@import "@/styles/_index.scss"; @import "@/styles/_variables.scss"; @import "@/styles/_surveyjs_overwrite.scss";


$blue-light: rgba(146, 215, 255, 0.171);

.alert-wrapper {
  background: white;
  border-radius: 4px;
  margin: 4px 0;
}

.alert {
  //display: inline-block;
  background: $blue-light;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 4px 6px;
  line-height: 24px;
  font-size: 12px;
  list-style-position: inside;
}

