@import "@/styles/_index.scss"; @import "@/styles/_variables.scss"; @import "@/styles/_surveyjs_overwrite.scss";


#tq-data-chart {

  position: relative;
  height: 100%;
  width: 100%;
  display: flex;

  padding: 7px;
  overflow: auto;

  .chart-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .chart {
    height: 100%;
    width: 100%;
  }

  .resizable {
    margin: auto;
    position: relative;
    width: 90%;
    height: 70%;
    max-height: 99%; /* This prevents the parent from growing when resizing manually */
  }

  .resize-handle{
    width: 10px;
    height: 10px;
    border: 3px solid #aaa;
    position: absolute;
  }

  .resize-handle.top-left {
    left: -5px;
    top: -5px;
    cursor: nwse-resize; /*resize cursor*/
  }

  .resize-handle.top-right {
    right: -5px;
    top: -5px;
    cursor: nesw-resize;
  }

  .resize-handle.bottom-left {
    left: -5px;
    bottom: -5px;
    cursor: nesw-resize;
  }

  .resize-handle.bottom-right {
    right: -5px;
    bottom: -5px;
    cursor: nwse-resize;
  }

  .container {
    position: absolute;
    background: red;
    overflow: auto;
  }

  :deep {
    .highcharts-container {
      width: 100% !important;
      height: 100% !important;
    }

    .highcharts-exporting-group {
      display: none !important;
    }
  }

}
