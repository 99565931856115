.tq-html {
  font-size: 16px;
  line-height: 1.4em;

  p {
    margin: 6px 0 12px;
  }

  h2 {
    font-size: 24px;
    margin: 30px 0 24px;
    line-height: 1.2em;
  }

  h3 {
    font-size: 18px;
    margin: 24px 0 16px;
    line-height: 1.2em;
  }

  ul {
    margin: 30px 0;
  }

  li {
    font-size: 18px;
    margin: 0 0 16px;
  }

  hr {
    margin: 40px 0;
    border: 0;
    width: 95%;
    height: 1px;
    background-color: #ddd;
  }
  

  .tq-html-article {
    display: grid;
    grid-template-columns: 1fr 2fr;
    margin: 30px 0 30px;

    ul {
      margin: 20px 0;
    }

    li {
      font-size: 16px;
      margin: 0 0 14px;
    }
    

    .tq-html-image {
      margin: 0 0 20px;

      img {
        width: 100%;
        height: auto;
      }
    }

    .tq-html-content {
      padding: 0 0 0 30px;

      .tq-html-header-1 {
        font-size: 18px;
        font-weight: 600;
        margin: 6px 0 12px;
      }

      .tq-html-header-2 {
        font-size: 16px;
        color: #999;
        font-weight: 600;
        margin: 5px 0 16px;
      }

      .tq-html-text {
        margin: 0 0 16px;
      }
    }
  }
}

@media only screen and (max-width: 600px){
  .tq-html {
    .tq-html-article {
      grid-template-columns: 1fr;
      
      .tq-html-content {
        padding: 0;
      }
    } 
  }
}