@import "@/styles/_index.scss"; @import "@/styles/_variables.scss"; @import "@/styles/_surveyjs_overwrite.scss";

#tq-results-table {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .tq-loading-data {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;

    .md-progress-bar {
      width: 100%;
    }

    .tq-loading-data-content {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
    }
  }

  .result-detail {
    display: flex;
    flex: 0 0 400px;
    min-width: 0;
    position: relative;
    transition: all 300ms;
  }

  .result-detail-close {
    flex: 0 0 0 !important;
  }

  .result-detail-opened {
    transition: all 0ms;
  }

  .result-detail-handle {
    position: absolute; 
    top: 0;
    left: -2px;
    bottom: 0;
    width: 5px;
    z-index: 1000;
    cursor: col-resize;
  }

  .dataset-button-wrapper {
    position: absolute;
    bottom: 15px;
    right: 15px;
    
    .add-button {
      margin: 0 0 0 10px;
    }

    .start-button {
      margin: 0 0 0 10px;
    }
  }

  :deep {
    /* Expand cell on mouse over if not completely visible */
    .ag-theme-balham .ag-cell-value:not(.index-cell):hover {
      overflow: visible;
      z-index: 1000;
      background: inherit;
      width: auto !important;
    }    

    /* Make header menu icon occupy no space if not displayed */
    .ag-header-cell:not(:hover) .ag-header-cell-menu-button {
      width: 0;
      overflow: hidden;
    }

    .ag-header-cell-label {
      color: #333;
    }

    .ag-root-wrapper {
      border: none;
    }

    .ag-status-bar {
      border: none;
    }
  }
}
