@import "@/styles/_index.scss"; @import "@/styles/_variables.scss"; @import "@/styles/_surveyjs_overwrite.scss";


.sb-selector {
    position: relative;
    width: 200px; /* must be half of controls table width*/
    height: 200px; /* must be half of controls table width*/
    overflow: hidden;
    touch-action: none; /* Prevent default touch behaviour like scrolling or pinch-zoom */
    background-color: red;
}

.sb-selector .sb-event-target {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    outline: 0;
    background: rgba(0, 0, 0, 0); /* Makes sure events and mouse pointers work properly on IE10 */
    cursor: crosshair
}

.sb-selector .h-panel {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #f00;
}

.sb-selector .s-panel {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, #fff 0, rgba(255, 255, 255, 0) 100%);
}

.sb-selector .b-panel {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, #000 100%);
}

.sb-selector > .indicator {
    position: absolute;
    left: 0;
    top: 0;
    width: 16px;
    height: 16px;
    border: 3px solid rgba(255, 255, 255, .8);
    border-radius: 50%;
    margin: -8px 0 0 -8px;
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.5);
    box-sizing: border-box;
    transition: 0s ease;
    transition-property: left, top, width, height, border-width, margin;
    transition-duration: 0s, 0s, 0.2s, 0.2s, 0.2s, 0.2s, 0.2s;
}

.sb-selector.focused > .indicator {
    width: 32px;
    height: 32px;
    border-width: 11px;
    margin: -16px 0 0 -16px;
}

.sb-indicator {
    position: absolute;
    left: 0;
    top: 0;
    width: 16px;
    height: 16px;
    border: 3px solid rgba(255, 255, 255, .8);
    border-radius: 50%;
    margin: -8px 0 0 -8px;
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.5);
    box-sizing: border-box;
    transition: 0s ease;
    transition-property: left, top, width, height, border-width, margin;
    transition-duration: 0s, 0s, 0.2s, 0.2s, 0.2s, 0.2s, 0.2s;
}

.sb-indicator {
    width: 32px;
    height: 32px;
    border-width: 11px;
    margin: -16px 0 0 -16px;
}

.hue-slider {
    position: relative;
    display: block;
    height: 24px;
    width: 200px;  /* Currently the slider does not react on size changes, therefore we need a constant width in any case */
    touch-action: none; /* Prevent default touch behaviour like scrolling or pinch-zoom */
    margin-top: 8px;
}

.hue-slider > .slider-track {
    background: linear-gradient(to right, #f00 0, #ff0 16.67%, #0f0 33.33%, #0ff 50%, #00f 66.67%, #f0f 83.33%, #f00 100%);
    position: absolute;
    left: 0;
    top: 4px;
    width: 100%;
    height: 16px;
}

.hue-slider .track-overlay,
.hue-slider .indicator-overlay {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.hue-slider > .slider-indicator {
    background-color: rgba(0, 0, 0, 0.2);
    border: 2px solid #fff;
    box-shadow:  0px 1px 3px rgba(0, 0, 0, 0.7);
    position: absolute;
    top: 5px;
    left: 1px;
    width: 14px;
    height: 14px;
    margin-left: -7px;
    border-radius: 50%;
    transition: 0s ease;
    transition-property: left, width, height, top, margin-left;
    transition-duration: 0s, 0.2s, 0.2s, 0.2s, 0.2s;
}

.hue-slider:hover > .indicator {
    top: 4px;
    width: 16px;
    height: 16px;
    margin-left: -8px;
}

.hue-slider.focused > .indicator {
    top: 0;
    width: 24px;
    height: 24px;
    margin-left: -12px;
}

.hue-slider .indicator-overlay {
    border-radius: 50%;
}

.hue-slider > .event-target {
    position: absolute;
    cursor: ew-resize;
    width: calc(100% + 14px);
    left: calc(0px - 7px);
    top: 0;
    height: 100%;
    outline: 0;
    background: rgba(0, 0, 0, 0); /* Makes sure events and mouse pointers work properly on IE10 */
}

.md-input {
  width: 100%;
}

