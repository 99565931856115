@import "@/styles/_index.scss"; @import "@/styles/_variables.scss"; @import "@/styles/_surveyjs_overwrite.scss";



.slider {
    position: relative;
    display: block;
    height: 24px;
    width: 200px;  /* Currently the slider does not react on size changes, therefore we need a constant width in any case */
    touch-action: none; /* Prevent default touch behaviour like scrolling or pinch-zoom */
}

.slider-track {
    background-color: #757575;
    position: absolute;
    left: 0;
    top: 11px;
    width: 100%;
    height: 2px;
}

.slider-track-overlay,
.slider-indicator-overlay {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.slider-indicator {
    background-color: #888;
    position: absolute;
    top: 5px;
    width: 14px;
    height: 14px;
    margin-left: -7px;
    border-radius: 50%;
    transition: 0s ease;
    transition-property: left, width, height, top, margin-left;
    transition-duration: 0.0s, 0.1s, 0.1s, 0.1s, 0.1s;
}

.slider:hover > .slider-indicator {
    top: 4px;
    width: 16px;
    height: 16px;
    margin-left: -8px;
}

.slider.focused > .slider-indicator {
    top: 0;
    width: 24px;
    height: 24px;
    margin-left: -12px;
}

.slider-indicator-overlay {
    border-radius: 50%;
}

.slider-event-target {
    position: absolute;
    cursor: ew-resize;
    width: calc(100% + 14px);
    left: calc(0px - 7px);
    top: 0;
    height: 100%;
    outline: 0;
    background: rgba(0, 0, 0, 0); /* Makes sure events and mouse pointers work properly on IE10 */
}
