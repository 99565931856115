@import "@/styles/_index.scss"; @import "@/styles/_variables.scss"; @import "@/styles/_surveyjs_overwrite.scss";

.email-recipients {
  display: flex;
  flex-direction: column;
}

.recipient {
  padding: 2px 16px;
}

.email-template {
  margin-top: 16px;
  margin-bottom: 16px;
}

.email-preview {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: hidden;
}

.language-field {
  margin: 0 0 0 16px;
  max-width: 100px;
}

.subject-preview {
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 16px;
  overflow: hidden;
}

.content-preview {
  flex: 1 1 auto;
  margin: 16px;
}

.text-preview {
  white-space: pre-wrap;
}

.no-preview {
  margin-top: 16px;
  opacity: 0.7;
}

.recipients-table-container {
  display: block;
  height: 192px;
  overflow: auto;
  margin-top: 16px;
  flex: 1 1 auto;
  overflow-y: scroll;
}

.recipients-table {
  padding: 0;
  border: none;
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;
}

.recipients-table th {
  position: sticky;
  top: 0;
  padding: 6px 12px;
  border: none;
  border-bottom: 1px solid #e0e0e0; ;
  cursor: default;
  z-index: 5;
  background: #ffffff;
}

.recipients-table tr {
  padding: 6px 0;
  border: none;
}

.recipients-table td {
  border-bottom: none;
  padding: 4px 12px;
  border-bottom: 1px solid #e0e0e0;
  cursor: default;
  opacity: 0.7;
}

.recipients-table .ready td {
  cursor: pointer;
  opacity: 1;
}

.recipients-table .selected td {
  background:#b7e4ff;
}

.id-column-header {
  text-align: center;
}

.address-column-header {
  text-align: left;
}

.status-column-header {
  text-align: left;
}

.id-column-cell {
  text-align: center;
  vertical-align: top;
}

.address-column-content {
  text-align: left;
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 4px;
}

.status-column-cell {
  text-align: left;
  white-space: nowrap;
  vertical-align: top;
}

.preview-heading {
  margin-bottom: 12px;
}

.preview-subheading {
  margin-bottom: 12px;
}

.preview-content-button {
  background: none;
  border: none;
  border-radius: 0;
  padding: 0;
  margin: 0;
  border-bottom: 3px solid transparent;
  opacity: 0.7;
}

.preview-content-button::before {
  background: none !important;
}

.preview-content-button:hover {
  opacity: 1;
  border-bottom: 3px solid #c0c0c0;
}

.preview-content-button.selPreviewContentButton {
  opacity: 1;
  border-bottom: 3px solid red;
}
