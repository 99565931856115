@import "@/styles/_index.scss"; @import "@/styles/_variables.scss"; @import "@/styles/_surveyjs_overwrite.scss";

.input-treeselect {

  .vue-treeselect__label-container:hover .vue-treeselect__checkbox--checked,
  .vue-treeselect__label-container:hover .vue-treeselect__checkbox--checked:hover,
  .vue-treeselect__label-container:hover .vue-treeselect__checkbox--indeterminate,
  .vue-treeselect__label-container:hover .vue-treeselect__checkbox--indeterminate:hover,
  .vue-treeselect__checkbox--indeterminate,
  .vue-treeselect__checkbox--checked,
  .vue-treeselect__checkbox--checked:hover,
  .vue-treeselect__checkbox--unchecked:hover {
    border-color: $red;
    background: $red;
  }
  .vue-treeselect__menu {
    border: 1px solid #eee;
    border-radius: 0;
    min-height: 320px;
  }
  .vue-treeselect__control {
    display: none;
  }
  .vue-treeselect__icon-warning {
    background: $red;
  }

  .vue-treeselect__label-container:hover .vue-treeselect__checkbox--unchecked,
  .vue-treeselect__label-container:hover .vue-treeselect__checkbox--unchecked:hover {
    border-color: $red;
  }
  .vue-treeselect__icon-loader::before {
    background: $red-light;
  }
  .vue-treeselect__icon-loader::after {
    background: $red;
  }
  .vue-treeselect--open-below .vue-treeselect__menu {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .select-all-container {
    display: flex;
    align-items: center;
  }

  .select-all-checkbox {
    margin: 0 5px 0;
    transform: scale(0.7);
    
    &.md-checked .md-checkbox-container {
      background: var(--md-theme-default-primary-on-background, #cd041e);
      border-color: var(--md-theme-default-primary-on-background, #cd041e);
      border-radius: 4px;
    }

    &.md-indeterminate .md-checkbox-container:after {
      border-color: white;
    }

  }

}


