@import "@/styles/_index.scss"; @import "@/styles/_variables.scss"; @import "@/styles/_surveyjs_overwrite.scss";

.start {
  flex: 1;
  position: relative;
  width: 100%;
  max-width: 1560px;
  background: #fff;

  .column {
    padding: 20px 0 0
  }

  .content-padding {
      padding: 0 36px;
  }

  .header {
    padding: 0 36px;
    position: relative;
    background-size: cover;
    background-position: center center;
    z-index: 5;
  }

  .headings {
    display: flex;
    overflow: auto;
    padding: 24px 0 0;
    //margin: -16px;
    margin-bottom: 0;

    .text {
      flex: 2 0 0;
      padding: 0 15px 0 0;
      
      h1 {
        margin: 16px 0;
        padding: 0;
        font-size: 28px;
        font-weight: 500;
        line-height: 1.2em;
        color: #fff;
      }

      h2 {
        margin: 16px 0;
        padding: 0;
        font-size: 16px;
        line-height: 1.2em;
        font-weight: 500;
        color: #fff;
      }
    }

    .logo {
      flex: 1 0 0;
      max-width: 200px;
    }
  }

  .html-content {
    margin: 20px 0;
  }

  .button-top {
    position: relative;
    height: 80px;

    .button-top-inner {
      background-size: cover;
      position: relative;
      padding: 0;
      height: 80px;
      display: flex;
      align-items: center;
      z-index: 100;
    }

    :deep {
      .md-button {
        margin: 0;
        border-radius: 5px;
        padding: 0px 10px;
        background: #fff;
        height: 50px;
      }
    }
  }

  :deep {
    .md-list {
      padding: 0;
    }
  }
}

@media only screen and (max-width: 600px){
  .start {
    .content-padding {
      padding: 0 18px;
    }

    .header {
      padding: 0 18px;
    }
  }
}
